if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log('HMR')
  })
}

import Alpine from 'alpinejs'

import '../css/site.css'

console.log('Hi, Yahzee!')
